export type SiteConfig = typeof siteConfig
export const siteConfig = {
    name: "Sprunki Sonic",
    slogan: "slogan",
    icon: "/favicon.ico",
    appleIcon: "/apple-touch-icon.png",
    gameIframe: "https://www.gameflare.com/embed/sprunki-sonic/",
    domain: "sprunkisonic.org",
    gaId: "G-SBXC1GR5TM",
    navbarItems: [
        {
            title: "title",
             href: "/",
        }
    ],
}
